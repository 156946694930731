export class Candidato{
  constructor(
    public id: string,
    public nombre: string,
    public apellidos: string,
    public tipoEmprendedor: string,
    public userid?: string,
    public email?: string,
    public telefono?: string,
    public imgCandidato?: string,
    public nombreIniciativa?: string,
    public fechaDesde?: Date,
    public fechaHasta?: Date
    

  )
  {

  }
}

import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { delay, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Candidato } from './candidatos.model';
import { HttpClient } from '@angular/common/http';


interface CandiatoData {
  nombre: string,
  apellidos: string,
  tipoEmprendedor: string,
  userId: string

}

@Injectable({providedIn:'root'})
export class ReservaService{
  private _candidatos = new BehaviorSubject<Candidato[]>([]);

  get candidatos(){
    // eslint-disable-next-line no-underscore-dangle
    console.log('this._candidatos');
    console.log(this._candidatos);
    return  this._candidatos.asObservable();
  }

  constructor(private authService: AuthService,
    private http: HttpClient){

  }


  addCandidato(nombre: string, apellidos: string, tipoEmprendedor: string


  ){
      let generateId: string;
      let newCandidato: Candidato;
      return this.authService.userId.pipe(take(1), switchMap(userId =>
        {
          if(!userId){
            throw new Error('Usuario no encontrado');
          }
        newCandidato =  new Candidato(
            Math.random().toString(),        
            nombre,
            apellidos,
            tipoEmprendedor,        
            userId);
            return this.http.post<{name:string}>('https://mvpe-doctorado-default-rtdb.europe-west1.firebasedatabase.app/candidatos.json',
            {...newCandidato,id:null})

        }), switchMap(
          resData => {
            generateId =resData.name;
            return this.candidatos;
          }
        ), take(1),tap(candidatos => {
          newCandidato.id = generateId;
           this._candidatos.next(candidatos.concat(newCandidato));
        })
        );

     

  }

  eliminarCandidato(candidatoId: string)
  {
    return this.http.delete(`https://mvpe-doctorado-default-rtdb.europe-west1.firebasedatabase.app/candidatos/${candidatoId}.json`
    ).pipe(switchMap(() => {
      return this.candidatos;
    }),take(1), tap(candidatos => {
      this._candidatos.next(candidatos.filter(c =>
        c.id !== candidatoId));
    }));
    

  }

  fetchCandidatos(){
    return this.http.get<{[key: string]: CandiatoData}>(`https://mvpe-doctorado-default-rtdb.europe-west1.firebasedatabase.app/candidatos.json?orderBy="userId"`)
    .pipe(map( candidatoData => {
      const candidatos = [];
      for (const key in candidatoData){
        if(candidatoData.hasOwnProperty(key))
        {
         candidatos.push(new Candidato(key, 
          candidatoData[key].nombre,
          candidatoData[key].apellidos,
          candidatoData[key].tipoEmprendedor
          ));
        }
      }
      return candidatos;
    }), tap(candidatos => {
      this._candidatos.next(candidatos);
    })
  
    );
  }
}


import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Iniciativa } from '../../iniciativa.model';
import { IniciativasService } from '../../iniciativas.service';

@Component({
  selector: 'app-oferta-reservas',
  templateUrl: './oferta-reservas.page.html',
  styleUrls: ['./oferta-reservas.page.scss'],
})
export class OfertaReservasPage implements OnInit, OnDestroy {
  iniciativa: Iniciativa;
  private iniciativaSub: Subscription;

  constructor(private route: ActivatedRoute,
              private navCtrl: NavController,
              private iniciativasServices: IniciativasService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      if(!paramMap.has('iniciativaId')){
        this.navCtrl.navigateBack('/iniciativas/tabs/ofertas');
        return;
      }
      console.log (paramMap.get('iniciativaId'));
      this.iniciativaSub = this.iniciativasServices.getIniciativa(paramMap.get('iniciativaId')).subscribe(
        iniciativa => {
          this.iniciativa = iniciativa;
        }
      );

    });

  }


  ngOnDestroy(){
    if(this.iniciativaSub){
      this.iniciativaSub.unsubscribe();
    }
  }

}


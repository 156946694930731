import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Iniciativa } from 'src/app/iniciativas/iniciativa.model';
import { IniciativasService } from 'src/app/iniciativas/iniciativas.service';
@Component({
  selector: 'app-editar-canvas',
  templateUrl: './editar-canvas.page.html',
  styleUrls: ['./editar-canvas.page.scss'],
})
export class EditarCanvasPage implements OnInit, OnDestroy{

  iniciativa: Iniciativa;
  form: FormGroup;
  private iniciativaSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private iniciativasService: IniciativasService,
    private navCtrl: NavController,
    private router: Router,
    private loadingCtrl: LoadingController
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      if (!paramMap.has('iniciativaId')) {
        this.navCtrl.navigateBack('/iniciativas/tabs/descubrir');
        return;
      }
      this.iniciativaSub = this.iniciativasService.getIniciativa(paramMap.get('iniciativaId')).subscribe(
        iniciativa => {
          this.iniciativa = iniciativa;
          console.log(this.iniciativa);
          this.form = new FormGroup({
            cSociosClaves: new FormControl(this.iniciativa.cSociosClaves,{
              updateOn:'blur',
              validators:[Validators.required]
            }),
            descripcion: new FormControl(this.iniciativa.descripcion,{
              updateOn:'blur',
              validators:[Validators.required, Validators.maxLength(180)]}),
              nifEmprendedor: new FormControl(this.iniciativa.nifEmprendedor,{
                updateOn:'blur',
                validators:[Validators.required, Validators.maxLength(14)]}),

              emprendedor: new FormControl(this.iniciativa.emprendedor,{
                updateOn:'blur',
                validators:[Validators.required, Validators.maxLength(180)]}),

          });
        }
      );
    });
  }

  ngOnDestroy(){
    if(this.iniciativaSub){
      this.iniciativaSub.unsubscribe();
    }

  }

 /* onActualizarDatos(){
    if(!this.form.valid){
      return;
    }
    this.loadingCtrl.create({
      message: 'Actualizando iniciativa...'
    }).then(loadingEl => {
      loadingEl.present();
      this.iniciativasService.updateIniciativa(
        this.iniciativa.id,
        this.form.value.titulo,
        this.form.value.descripcion,
        this.form.value.nifEmprendedor,
        this.form.value.emprendedor).subscribe(
          () => {
            loadingEl.dismiss();
            this.form.reset();
            this.router.navigate(['/iniciativas/tabs/descubrir/' + this.iniciativa.id]);
          }
        );

    });

  }
*/

}
